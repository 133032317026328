// login part
.containerlogin {
  .card-header {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 20px;
  }
  input[type='email'],
  input[type='password'] {
    width: 80%;
    appearance: none;
    border-radius: 0px;
    border: 2px solid #b7dff8;
    padding: 10px;
    font-size: 1.1em;
  }
  .loginBtn {
    max-width: 200px;
    display: block;
    width: 100%;
    background: #b7dff8;
    border-radius: 0px;
    appearance: none;
    text-align: center;
    color: black;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    font-weight: bold;
    font-size: 1.5em;
    text-decoration: none;
    border: 2px solid #b7dff8;
    &:hover {
      background: white;
    }
  }
}
//admin menu
#navAdmin {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 250px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
  .container {
    width: 100% !important;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    .menuItems {
      text-align: center;
      height: 100%;
      margin-top: 0;
      padding: 0;
      .logo {
        text-decoration: none;
        img {
          margin-top: 15px;
          width: 100px;
        }
        b {
          color: black;
        }
      }
      ul {
        border-top: 1px solid #eee;
        margin-top: 15px;
        padding-top: 15px;
        text-align: left;
        position: relative;
        height: 100%;
        margin-bottom: 0;
        li {
          display: block;
          &:last-child {
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            border-top: 1px solid #eee;
          }
        }
        #logoutLi {
          padding-top: 10px;
          text-align: center;
          i{
              margin-right: 5px;
          }
        }
      }
    }
  }
}
#mainAdmin {
  background: rgba(0,0,0,0.03);
  float: right;
  width: calc(100% - 250px);
  min-height: 100vh;
}
#adminContainer {
  margin: 30px;
  width: calc(100% - 60px) !important;
  padding: 0;
  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
}
// admin part
.contentAdmin{
    margin-top: 0 !important;
}
section {
  background: #fff;
  // border-radius: 10px;
  h2 {
    font-weight: bold;
    margin-top: 0;
    text-align: center;
    font-size: 0.9em;
    // padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
}

.infoSectionAdmin {
  float: left;
  width: calc((100% - 40px) / 3);
  margin-bottom: 20px;
  padding: 15px;
  margin-right: 20px;
  min-height: 180px;
}
#top3 {
  ol {
    display: block;
    margin-left: 1.1em;
    list-style: none;
    margin: 0 auto;
    li {
        font-size: 1em;
        background: lighten(#eee, 2);
        width: 95%;
        margin: auto;
        margin: 5px auto;
        padding: 10px 20px 8px 20px;
        border-radius: 20px;
        &.position1{
            background: $baseColor;
            font-size: 1.1em;
            transform: scale(1.05);
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
            margin-bottom: 7px;
            span, p{
                font-weight: bold;
            }
        }
        p{
            margin-bottom: 0;
        }
        span{
            display: inline-block;
            color: black;
            font-weight: normal;
            font-size: 1em;
        }
        .name{
            display: inline-block;
            margin-left: 10px;
        }
      .count {
        display: inline-block;
        float: right;
      }
    }
  }
}

#totalSentAudio {
  margin-right: 0;
  position: relative;
  overflow: hidden;
  .imageFly{
      position: absolute;
      width: 100px;
      height: 100px;
      opacity: 0.3;
      right: 20px;
      bottom: 10px;
      background-image: url(../images/Fly.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
  }
}
.ct-series-a .ct-area, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-slice-pie{
    fill: $baseColor !important;
}
.ct-series-b .ct-area, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-slice-pie{
    fill: rgba(0,0,0,0.05) !important;
}
#doughnutCanvas{
    position: relative;
    .percentage{
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 2.5em !important;
    }
    #Chart{
        overflow: hidden;
        svg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 10px;
            width: 230px !important;
            height: 230px !important;
       }
    }
}
#lastSentAudio,
#totalSentAudio{
  h2 {
    margin-bottom: 0;
  }
  .info {
    display: table;
    width: 100%;
    p.big {
      width: 100%;
      font-weight: bold;
      font-size: 3em;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }
}

#formHandleAudio {
  float: left;
  margin-right: 20px;
  width: calc((100% - 40px) / 3);
  padding: 15px;
  #handleAudioAdmin {
    label {
      margin: 0;
      font-weight: normal;
      width: 100%;
    }
    input[type='text'] {
      width: 100%;
      appearance: none;
      border-radius: 0px;
      border: none;
      border-bottom: 2px solid #b7dff8;
      padding: 0 0 5px 0;
      font-size: 1.1em;
    }
    .inputFile{
        display: block;
        position: relative;
    }
    input[type='file'] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        opacity: 0;
        cursor: pointer;
    }
    .browseFileAudio {
      background: #b7dff8;
      color: black;
      min-height: 35px;
      display: inline-block;
      padding: 10px 20px;
      border: none;
      float: left;
    }
    .fileInfo {
      margin-left: 10px;
      float: left;
      display: block;
      margin-top: calc((35px - 16px) / 2);
    }
    .littleNote {
      margin-top: 7px;
      font-size: 0.7em;
    }
    input[type='submit'] {
      display: inline-block;
      background: #b7dff8;
      border-radius: 0px;
      appearance: none;
      text-align: center;
      color: black;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 10px 10px 8px 10px;
      font-weight: bold;
      font-size: 1.1em;
      text-decoration: none;
      border: 2px solid #b7dff8;
      float: right;
      &:hover {
        background: white;
      }
    }
    #editButton {
      display: none;
    }
  }
}
#tableContent {
  text-align: center;
  overflow: hidden;
  float: right;
  width: calc((100% - ((100% - 40px) / 3)) - 20px);
  padding: 15px;
  min-height: 400px;
  .tableWrapper {
    max-height: 88%;
    overflow-y: auto;
    table {
      border-collapse: collapse;
      margin: 0 auto;
      width: 100%;
      tr {
        border-bottom: 1px solid darken(#b7dff8, 10%);
        &:hover {
          background: lighten(#b7dff8, 10%);
        }
        &:first-child {
          border: none;
        }
        th {
          font-weight: bold;
          background: #b7dff8;
          padding: 10px 30px;
        }
        th.centerth {
          min-width: 150px;
          text-align: center;
        }
        td {
          padding: 15px 20px;
          text-align: left;
          max-width: 450px;
          overflow-x: hidden;
          span {
            font-size: 0.8em;
            &:hover {
              cursor: pointer;
            }
          }
          span.fa-pencil {
            color: black;
          }
          span.fa-trash-o {
            color: red;
          }
        }
        td.spantd {
          padding-left: 10px;
          padding-right: 10px;
          width: 30px;
        }
        td.center {
          text-align: center;
        }
      }
      audio {
        display: none;
      }
    }
  }
}

.deleteConfirmation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1000;
    display: none;
    .wrapper-delete{
        width: 100%;
        max-width: 400px;
        background: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h2{
            background: rgba(0,0,0,0.05);
            margin: 0;
            padding: 20px;
            text-align: center;
            font-weight: bold;
            font-size: 1.5em;
        }
        input{
            float: right;
        }
        p{
            margin-bottom: 40px;
            margin-top: 20px;
        }
        input.cancel{
            appearance: none;
            border-radius: 0;
            border: none;
            text-decoration: underline;
            padding: 10px;
        }
        input.delete{
            appearance: none;
            border-radius: 0;
            border: 2px solid $baseColor;
            color: white;
            background: $baseColor;
            padding: 10px;
            font-weight: bold;
            &:hover{
                background: transparent;
                color: $baseColor;
            }
        }
        .bodyWrap{
            padding: 20px;
        }
        a.closeDeleteConfitmation{
            position: absolute;
            right: 15px;
            top: 10px;
            width: 20px;
            height: 20px;
            opacity: 0.3;
            &:hover {
              opacity: 1;
            }
            &:before, &:after {
              position: absolute;
              left: 15px;
              content: ' ';
              height: 20px;
              width: 2px;
              background-color: #333;
            }
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
        }
    }
}

.pop-upCompliment{
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    background: rgba(0,0,0,0.5);
    width: 100% !important;
    height: 100% !important;
    z-index: 1000;
    display:none;
    a.close{
        position: absolute;
        right: 15px;
        top: 10px;
        width: 20px;
        height: 20px;
        opacity: 0.3;
        &:hover {
          opacity: 1;
        }
        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 20px;
          width: 2px;
          background-color: #333;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
    }
    &.displayNow{
        display: block;
    }
    #editButton{
        display: block !important;
    }
    .content-wrapper{
        width: 100%;
        max-width: 400px;
        background: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h2{
            background: rgba(0,0,0,0.05);
            margin: 0;
            padding: 20px;
            text-align: center;
            font-weight: bold;
            font-size: 1.5em;
        }
        .bodyWrap{
            padding: 20px;
        }
    }
}

@media screen and(max-width: 1500px){
  #lastSentAudio {
    .info {
      p {
        font-size: 2em;
      }
    }
  }
}
@media screen and(max-width: 1200px){
    .open #navAdmin{
        width: 100%;
        .menuItems{
            display: block;
        }
    }
  #navAdmin {
    width: 85px;
    height: 100% !important;
    background: white;
    overflow: scroll;
    .menuItems{
        box-shadow: none;
        display: none;
        ul{
            height: 80% !important;
        }
    }
    .container{
        max-width: 100%;
    }
    #trigger {
        position: absolute;
        right: 16px;
        top: 10px;
      span:nth-child(2),
      span:nth-child(3), {
        top: 15px;
      }
      span:nth-child(4) {
        top: 30px;
      }
    }
  }
  #mainAdmin {
    width: calc(100% - 85px);
  }
}
@media screen and(max-width: 970px){
    #top3.infoSectionAdmin {
        float: left;
        width: 100%;
        margin-right: 0;
    }
    .infoSectionAdmin {
        float: left;
        width: calc((100% - 20px) / 2);
        margin-bottom: 20px;
        padding: 15px;
        margin-right: 20px;
        min-height: 180px;
    }
}
@media screen and(max-width: 925px){
  #top3 {
    ol {
      li {
        .name {
          width: 50%;
        }
      }
    }
  }
}
@media screen and(max-width: 850px){
  #formHandleAudio,
  #tableContent {
    float: none;
    width: 100%;
  }
  #formHandleAudio {
    margin-bottom: 20px;
  }
  .infoSectionAdmin {
    margin-bottom: 20px;
  }
}
@media screen and(max-width: 820px){
  #top3 {
    ul {
      li {
        .name {
          width: calc(100% - 30px);
        }
      }
    }
  }
}
@media screen and(max-width: 600px){
    #top3 ol li .count{
        display: none;
    }
    #top3 ol li .name {
    width: 80%;
    }
    #top3 ol li{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #doughnutCanvas #Chart svg {
        width: 180px !important;
        height: 180px !important;
    }
    #totalSentAudio .imageFly {
        width: 80px;
        height: 80px;
    }
  #navAdmin {
    width: 40px;
    #trigger {
      width: 25px;
      text-align: left;
      right: 6px;
      span{
         height: 4px;
         &:nth-child(2), &:nth-child(3) {
           top: 10px;
         }
         &:nth-child(4) {
            top: 20px;
         }
      }
    }
  }
    .infoSectionAdmin {
        float: left;
        width: 100%;
        margin-right: 0;
        min-height: 200px;
    }
    #totalSentAudio{
        margin-bottom: 0;
    }
  #mainAdmin {
    width: calc(100% - 40px);
  }
  #formHandleAudio {
    margin-top: 20px;
  }
  #tableContent {
    margin-bottom: 20px;
  }
}
@media screen and(max-width: 500px){
  #tableContent {
    overflow-x: scroll;
  }
  .content.contentAdmin h2 {
    font-size: 1em;
  }
  #formHandleAudio {
    #handleAudioAdmin {
      input[type='text'] {
        width: 100%;
      }
    }
  }
  #tableContent {
    .deleteConfirmation {
      margin-left: 50px;
    }
  }
}

@media screen and(max-width: 350px) {
  #navAdmin {
    .container {
      .menuItems {
        width: 300px;
      }
    }
  }
}
