.contentContact{
    margin-top: 250px;
    color: black;
    p{
        line-height: 150%;
        font-size: 1.05em;
    }
    .mapwrap{
        margin-bottom: 200px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            right: 250px;
            bottom: -150px;
            height: 150px;
            width: 2px;
            background: black;
        }
        #map{
            width: 100%;
            height: 500px;
        }
    }
    .form{
        margin-bottom: 100px;
        position: relative;
        .title{
            float: right;
            text-align: right;
            &:after{
                right: 30px;
                left: auto;
            }
        }
    }
    #contactForm, #feedbackForm{
        max-width: 700px;
        margin: auto;
        width: 100%;
        text-align: center;
        fieldset{
            margin: 0.5em;
            float: left;
            width: calc(50% - 1em);
            &.full{
                width: calc(100% - 1em);
            }
            input{
                display: block;
                width: 100%;
                appearance: none;
                border-radius: 0;
                border: 3px solid $baseColor;
                padding: 15px 20px;
                font-size: 1em;
            }
            textarea{
                display: block;
                width: 100%;
                appearance: none;
                border-radius: 0;
                border: 3px solid $baseColor;
                padding: 15px 20px;
                font-size: 1em;
                height: 150px;
                resize: none;
            }
        }
        input.send{
            display: inline-block;
            background: $baseColor;
            border-radius: 0px;
            appearance: none;
            border: none;
            text-align: center;
            color: black;
            margin-top: 20px;
            padding: 20px 40px;
            font-weight: bold;
            font-size: 1.2em;
            text-decoration: none;
            border: 2px solid $baseColor;
            &:hover{
                background: white;
            }
        }
    }
    .title{
        margin-left: 80px;
        margin-right: 20px;
        position: relative;
        max-width: 250px;
        width: 100%;
        min-height: 150px;
        &:after{
            top: -22%;
            left: 30px;
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            background: $baseColor;
            z-index: -1;
        }
    }
    span{
        font-size: 3em;
        display: block;
        margin-bottom: 0;
        font-weight: bold;
        color: $baseColor;
    }
    h2{
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 0;
        font-size: 2em;
        letter-spacing: 2px;
        color: black;
    }
}

@media screen and(min-width: 900px){
    .contentContact{
        margin-top: 350px;
    }
}

@media screen and(min-width: 1200px){
    .contentContact{
        margin-top: 450px;
    }
}

@media screen and(max-width: 700px){
    .contentContact{
        .mapwrap{
            #map{
                height: 400px;
            }
        }
    }
}

@media screen and(max-width: 600px){
    .contentContact{
        #contactForm, #feedbackForm{
            fieldset{
                float: none;
                width: calc(100% - 1em);
            }
        }
    }
    .contentContact .title {
        margin-left: 0;
        h2{
            font-size: 1.5em;
        }
    }
}

@media screen and(max-width: 450px){
    .contentContact{
        margin-top: 520px;
    }
}
