.audioFiles {
  // margin-top: 350px;
  .audioFilesToListen {
    display: none;
  }
  a {
      margin-top: 30px;
    max-width: 350px;
    display: block;
    width: 100%;
    background: $baseColor;
    border-radius: 0px;
    appearance: none;
    border: none;
    text-align: center;
    color: black;
    margin-bottom: 20px;
    padding: 15px 10px;
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: none;
    border: 2px solid $baseColor;
    &:link {
      color: black;
      text-decoration: none;
    }
    &:visited {
      color: black;
      text-decoration: none;
    }
    &:hover{
      color: black;
      background: white;
      text-decoration: none;
    }
    &:active{
      color: black;
      text-decoration: none;
    }
  }
}
