nav{
    z-index: 100;
}
.menuItems{
    height: auto;
    background: white;
    text-align: center;
    margin-top: 30px;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    ul{
        list-style: none;
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
        li{
            display: block;
            a{
                color: $baseColor;
                padding: 10px;
                display: block;
                font-weight: bold;
                font-size: 1.5em;
                text-transform: uppercase;
                &:hover, &.active{
                    color: darken($baseColor, 15);
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}
.lang{
    float: left;
    width: calc(100% - 80px);
    text-align: right;
    margin-top: 32px;
    position: relative;
    &.extra{
        a{
            &:nth-child(3){
                padding-right: 10px;
                margin-right: 10px;
                &:after{
                    content: '';
                    background: black;
                    width: 2px;
                    height: 85%;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
    a{
        color: black;
        font-size: 1.3em;
        font-weight: bold;
        position: relative;
        &:focus{
            text-decoration: none;
        }
        &:hover{
            text-decoration: none;
            color: $baseColor;
        }
        &:first-child, &:nth-child(2){
            padding-right: 10px;
            margin-right: 10px;
            &:after{
                content: '';
                background: black;
                width: 3px;
                height: 85%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

#trigger{
    width: 50px;
    height: 50px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    color: $baseColor;
    font-size: 1.5em;
    margin-top: 10px;
    text-align: right;
    margin-bottom: 10px;
    float: right;
    .tekst{
        position: absolute;
        left: -50px;
        text-transform: uppercase;
        font-size: 0.8em;
        top: -5px;
        margin-left: -0.5em;
    }
  span{
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      margin-top: 1px;
      background: $baseColor;
  }
  span:nth-child(1) {
      top: 0px;
  }
  span:nth-child(2), span:nth-child(3) {
      top: 25px;
  }
  span:nth-child(4) {
      top: 50px;
  }
}
#trigger.open{
    span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    span:nth-child(2) {
        transform: rotate(45deg);
    }
    span:nth-child(3) {
        transform: rotate(-45deg);
    }
    span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}

@media screen and(min-width: 1200px){
    nav .container{
        max-width: 100%;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    nav{
        border-bottom: 1px solid rgba(0,0,0,0.03) !important;
    }
    .menuItems{
        display: block;
        height: auto;
        background: white;
        text-align: right;
        margin-top: 20px;
        padding: 0 20px;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        ul{
            list-style: none;
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            li{
                display: inline-block;
                a{
                    padding: 10px 20px;
                    color: $baseColor;
                    display: block;
                    font-weight: bold;
                    font-size: 1.3em;
                    text-transform: uppercase;
                    &:hover{
                        color: darken($baseColor, 15);
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .lang{
        float: none;
        width: 100%;
        background: rgba(0,0,0,0.03);
        text-align: right;
        margin-top: 0;
        padding: 10px;
        position: relative;
        a{
            color: black;
            font-size: 1.1em;
            font-weight: bold;
            position: relative;
            &:focus{
                text-decoration: none;
            }
            &:hover{
                text-decoration: none;
                color: $baseColor;
            }
            &:first-child, &:nth-child(2){
                padding-right: 10px;
                margin-right: 10px;
                &:after{
                    content: '';
                    background: black;
                    width: 2px;
                    height: 85%;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    #trigger{
        display: none;
    }
}
