.header{
    position: relative;
    .logo{
        img{
            width: 120px;
            margin-bottom: 10px;
            margin-left: -22px;
        }
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 3.5em;
        letter-spacing: 5px;
        text-decoration: none;
        position: relative;
        z-index: 100;
    }
    a{
        color: black;
        text-decoration: underline;
    }
    span.subtitle{
        font-size: 2.5em;
    }
    .text{
        position: relative;
        padding-left: 80px;
        max-width: 450px;
        margin-top: 20px;
        font-size: 1.2em;
        position: relative;
        color: black;
        z-index: 100;
        &:before{
            position: absolute;
            display: block;
            content: '';
            left: 0;
            top: 0;
            height: 6px;
            background: $baseColor;
            width: 70px;
        }
    }
    &.beluister{
        .background:after{
            display: none;
        }
    }
    .background{
        position: absolute;
        right: 0;
        top: 50px;
        width: 80%;
        height: 380px;
        background: $baseColor;
        z-index: 50;
        .mas{
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 380px;
            background-image: url(/images/antwerpse_fluisteraar.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.8;
        }
        &:after{
            content: '';
            position: absolute;
            left: 20%;
            bottom: -180px;
            height: 200px;
            width: 2px;
            background: black;
        }
    }
    .AntwerpseFlusiteraar{
        position: absolute;
        top: -60px;
        right: 20px;
        background-image: url(/images/img_fluisteraar.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 600px;
        width: 450px;
        z-index: 1;
    }
}
.content{
  .alert-success {
    z-index: 10;
    position: relative;
  }
    margin-top: 250px;
    color: black;
    .step1{
        margin-bottom: 100px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            right: 250px;
            bottom: -180px;
            height: 150px;
            width: 2px;
            background: black;
        }
        .errorUsername {
          margin-top: 30px;
          margin-left: 130px;
          margin-right: 20px;
          width: calc(100% - 150px);
        }
    }
    .step2{
        margin-bottom: 100px;
        position: relative;
        // &:after{
        //     content: '';
        //     position: absolute;
        //     left: 270px;
        //     right: auto;
        //     bottom: -180px;
        //     height: 150px;
        //     width: 2px;
        //     background: black;
        // }
        .title{
            float: right;
            text-align: right;
            &:after{
                top: 50px;
                right: 30px;
                left: auto;
            }
        }
        input[type='submit'], button{
          float: right;
          max-width: 350px;
          display: block;
          width: 100%;
          background: $baseColor;
          border-radius: 0px;
          appearance: none;
          border: none;
          text-align: center;
          color: black;
          margin-top: 20px;
          margin-bottom: 20px;
          padding: 20px;
          font-weight: bold;
          font-size: 1.5em;
          text-decoration: none;
          border: 2px solid $baseColor;
          &:hover{
              background: white;
          }
        }
    }
    .step2.withAfter:after {
      content: '';
      position: absolute;
      left: 270px;
      right: auto;
      bottom: -180px;
      height: 150px;
      width: 2px;
      background: black;
    }
    .step3{
        margin-bottom: 100px;
        .title{
            max-width: 200px;
            float: left;
            height: 185px;
        }
        .right{
            float: right;
            width: calc(100% - 350px);
            margin-top: 50px;
            a, input{
                cursor: pointer;
                max-width: 350px;
                display: block;
                width: 100%;
                background: $baseColor;
                border-radius: 0px;
                appearance: none;
                border: none;
                text-align: center;
                color: black;
                margin-bottom: 20px;
                padding: 20px;
                font-weight: bold;
                font-size: 1.5em;
                text-decoration: none;
                border: 2px solid $baseColor;
                &:hover{
                    background: white;
                }
            }
        }

    }
    .small{
        width: 100%;
        text-align: center;
        color: black;
        font-weight: 300;
        margin-top: 60px;
        display: block;
        font-weight: 700;
        font-size: 1.1em;
    }
    .small.mobile{
        display: none;
    }
    .step2 ul{
        list-style: none;
        display: block;
        margin-top: 0px;
        li{
            float: left;
            width: calc(33.33% - 2px - 2em);
            text-align: center;
            padding: 30px;
            margin: 1em;
            border: 1px solid black;
            cursor: pointer;
            position: relative;
            word-break: break-all;
            hyphens: auto;
            &.button{
                border: 3px solid $baseColor;
                position: relative;
                a{
                    color: $baseColor;
                    text-decoration: none;
                    font-weight: bold;
                    text-transform: uppercase;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    text-align: center;
                    padding: 30px;
                }
                &:hover a{
                    color: white;
                }
                &:before{
                    bottom: -9px;
                    right: 22px;
                }
                &:after{
                    border-color: transparent $baseColor transparent transparent;
                }
            }
            // &:hover,
            &.hover_effect,
            &.checked {
              color: white;
              font-weight: 700;
              background: $baseColor;
              border: 1px solid darken($baseColor, 10);
              &:before{
                  border-color: transparent $baseColor transparent transparent;
              }
              &:after{
                  border-color: transparent darken($baseColor, 10) transparent transparent;
              }
            }
            &:before{
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                bottom: -13px;
                right: 20px;
                border-style: solid;
                border-width: 0 18px 18px 0;
                border-color: transparent white transparent transparent;
                z-index: 100;
            }
            &:after{
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                bottom: -16px;
                right: 19px;
                border-style: solid;
                border-width: 0 20px 20px 0;
                border-color: transparent black transparent transparent;
            }
            input{
                position: absolute;
                cursor: pointer;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
    .title{
        margin-left: 80px;
        margin-right: 20px;
        position: relative;
        max-width: 250px;
        width: 100%;
        &:after{
            top: 50px;
            left: 30px;
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            height: 80%;
            mix-blend-mode: multiply;
            background: $baseColor;
            opacity: 0.7;
            z-index: -1;
        }
        // h2{
        //     mix-blend-mode: multiply;
        // }
    }
    span{
        font-size: 6em;
        display: block;
        margin-bottom: 0;
        font-weight: bold;
        color: $baseColor;
    }
    h2{
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 0;
        letter-spacing: 2px;
        color: black;
    }
    label{
        display: block;
        width: calc(100% - 150px);
        margin-right: 20px;
        font-size: 1.2em;
        margin-top: 40px;
        margin-left: 130px;
    }
    input#username{
        display: block;
        width: calc(100% - 150px);
        margin-right: 20px;
        margin-left: 130px;
        appearance: none;
        border-radius: 0;
        border: 6px solid $baseColor;
        padding: 20px;
        font-size: 1.2em;
    }

    #sendInformation{
        background: rgba(0,0,0,0.05);
        padding: 30px 30px 20px 30px;
        margin-left: 80px;
        margin-top: 40px;
        position: relative;
        display: none;
        opacity: 0;
        fieldset{
            float: left;
            width: calc(50% - 40px);
            margin: 10px 20px 0 20px;
            position: relative;
            &:first-child:after{
                content: '';
                width: 2px;
                height: calc(100% - 20px);
                position: absolute;
                right: -20px;
                top: 0;
                background: rgba(0,0,0,0.05);
            }
            #st-1 .st-btn{
                color: white;
                cursor: pointer;
                font-weight: bold;
                background: #0084FF;
                display: flex !important;
                justify-content: center;
                align-items: center;
                float: left;
                height: 40px;
                img{
                    height: 20px;
                    width: 20px;
                    top: auto;
                }
            }
            .social-button {
              text-decoration: none;
              .fa-whatsapp {
                  margin-left: 10px;
                  color: white;
                  cursor: pointer;
                  font-weight: bold;
                  background: #25D366;
                  display: flex !important;
                  justify-content: center;
                  align-items: center;
                  float: left;
                  height: 40px;
                  width: 50px;
                  position: relative;
                      transition: opacity 0.2s ease-in, top 0.2s ease-in;
                  &:before{
                      font-size: 0.3em;
                  }
                  &:hover{
                      opacity: .8;
                      top: -4px;
                  }
              }
            }
            #st-1, .social-button {
              float: left;
            }
        }
        &.openInfo{
            opacity: 1;
            display: block;
        }
        &:after{
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 25px 25px 25px;
            border-color: transparent transparent rgba(0,0,0,0.05) transparent;
        }
        label{
            width: 100%;
            margin-right: 0;
            font-size: 1em;
            margin-top: 0;
            margin-left: 0;
            font-weight: 300;
            margin-bottom: 20px;
        }
        .copyLink{
            background: $baseColor;
            width: 90px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $baseColor;
            padding: 10px;
            float: left;
            font-size: 1.2em;
            cursor: pointer;
            font-weight: bold;
            text-decoration: none;
            &:hover{
                border: 2px solid darken($baseColor, 10);
                background: darken($baseColor, 10);
            }
        }
        .message{
            display: none;
            color: darken($baseColor, 10);
            max-width: 250px;
            position: absolute;
            right: -9px;
            bottom: -24px;
            padding: 10px;
        }
        h3{
            font-weight: bold;
            margin: 0;
            font-size: 1.2em;
            text-transform: uppercase;
            margin-bottom: 5px;
            min-height: 20px;
        }
        input{
            display: block;
            width: 100%;
            appearance: none;
            border-radius: 0;
            border: 2px solid $baseColor;
            padding: 10px;
            font-size: 1.1em;
            margin-bottom: 20px;
            float: left;
            width: calc(100% - 90px);
            &.send{
                background: $baseColor;
                width: 90px;
                color: white;
                cursor: pointer;
                font-weight: bold;
                &:hover{
                    border: 2px solid darken($baseColor, 10);
                    background: darken($baseColor, 10);
                }
            }
        }
    }
}

.beluisterContent{
    margin-top: 250px !important;
    .boodschapZelf{
        background: rgba(0,0,0,0.05);
        padding: 30px 30px 20px 30px;
        margin-top: 40px;
        position: relative;
        margin-bottom: 40px;
        max-width: 400px;
        width: 100%;
        float: right;
        text-align: center;
        &:after {
            content: '';
            position: absolute;
            left: 10%;
            top: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 25px 25px 25px;
            border-color: transparent transparent rgba(0,0,0,0.05) transparent;
        }
        h2{
            font-size: 1.3em;
        }
        a{
            border: 2px solid darken($baseColor, 10);
            padding: 15px 30px 10px 30px;
            color: darken($baseColor, 10);
            display: inline-block;
            border-radius: 30px;
            margin-top: 20px;
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            &:hover{
                background: darken($baseColor, 10);
                color: white;
            }
        }
    }
}

@media (hover: hover) {
    .step2 {
      ul {
        li:hover {
          color: white;
          font-weight: 700;
          background: $baseColor;
          border: 1px solid darken($baseColor, 10);
          &:before{
              border-color: transparent $baseColor transparent transparent;
          }
          &:after{
              border-color: transparent darken($baseColor, 10) transparent transparent;
          }
        }
      }
    }
}



@media screen and(min-width: 900px){
    .header{
        .background{
            height: 500px;
            .mas{
                height: 500px;
            }
            iframe{
                height: 500px;
            }
            &:after{
                content: '';
                left: 5%;
            }
        }
    }
    .content{
        margin-top: 380px;
    }
    .beluisterContent{
        margin-top: 390px;
    }
}

@media screen and(min-width: 1200px){
    main{
        .container{
            max-width: 1400px;
            width: 90% !important;
        }
    }
    .header{
        .background{
            height: 600px;
            .mas{
                height: 600px;
            }
            iframe{
                height: 600px;
            }
            &:after{
                content: '';
                left: 5%;
            }
        }
    }
    .content{
        margin-top: 480px;
    }
}

@media screen and(max-width: 1200px){
    .beluisterContent{
        margin-top: 150px !important;
    }
}

@media screen and(max-width: 900px){
    .beluisterContent{
        margin-top: 50px !important;
    }
    .header{
        position: relative;
        .logo{
            font-size: 2.5em;
        }
        .text{
            max-width: 350px;
            font-size: 1.1em;
        }
        .background{
            height: 350px;
            .mas{
                height: 350px;
            }
            iframe{
                height: 350px;
            }
        }
        .AntwerpseFlusiteraar{
            top: 0px;
            right: 20px;
            height: 500px;
            width: 350px;
        }
    }
    .content{
        #sendInformation{
            fieldset{
                float: none;
                width: 100%;
                margin: 0;
                &:first-child:after{
                    display: none;
                }
                &:first-child{
                    margin-bottom: 40px;
                }
                &:first-child:before{
                    content: '';
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background: rgba(0,0,0,0.05);
                }
            }
        }
    }
}
@media screen and(max-width: 750px){
    .content{
        .step2 ul{
            li{
                width: calc(50% - 2px - 2em);
            }
        }
        .small{
            display: none;
        }
        .small.mobile{
            display: block;
        }
    }
}

@media screen and(max-width: 650px){
    .beluisterContent{
        // margin-top: -100px;
    }
    .beluisterContent{
        // margin-top: -70px !important;
    }
    .header{
        .logo{
            font-size: 2em;
        }
        .text{
            padding-left: 60px;
            &:before{
                width: 50px;
            }
        }
        .background{
            width: 70%;
        }
        .AntwerpseFlusiteraar{
            right: 0;
            top: 80px;
            height: 400px;
            width: 300px;
        }
    }

    .content{
        .step2{
            .title{
                float: right;
                text-align: right;
                &:after{
                    top: 50px;
                    right: 30px;
                    left: auto;
                }
            }
            &:after{
                left: 160px;
            }
        }
        .step3{
            .title{
                max-width: 150px;
                float: left;
                height: 185px;
            }
            .right{
                float: right;
                width: calc(100% - 250px);
                margin-top: 50px;
            }
            a, input{
                font-size: 1.2em;
            }
        }
        .title{
            margin-left: 20px;
        }
        #sendInformation{
            margin-left: 20px;
        }
    }
}

@media screen and(max-width: 550px){
    .content{
        .step3{
            .title{
                float: none;
                height: auto;
            }
            .right{
                float: none;
                width: 100% !important;
            }
            a, input{
                max-width: 100% !important;
            }
        }

        .title{
            margin-left: 20px;
            max-width: 150px;
            &:after{
                top: 40px;
            }
        }
        span{
            font-size: 5em;
        }
        h2{
            font-size: 1.5em;
        }
        label{
            width: calc(100% - 40px);
            font-size: 1.1em;
            margin-left: 20px;
        }
        input#username{
            width: calc(100% - 40px);
            margin-right: 20px;
            margin-left: 20px;
            font-size: 16px;
        }
        .step1 {
          .errorUsername {
            width: calc(100% - 40px);
            margin-right: 20px;
            margin-left: 20px;
          }
        }
        #sendInformation{
            margin-left: 0px;
        }
    }
}

@media screen and(max-width: 500px){
    .content{
        .step2 ul{
            li{
                width: calc(100% - 2px - 2em);
            }
        }
    }
}

@media screen and(max-width: 450px){
    .beluisterContent{
        margin-top: -80px !important;
    }
    .header{
        &.contact{
            .background{
                margin-top: 280px;
            }
        }
        &.contact{
            .background{
                margin-top: 180px;
            }
        }
        &.beluister{
            .background{
                margin-top: 320px;
            }
        }
        &.verhaal{
            .background{
                margin-top: 180px;
            }
        }
        .logo{
            img{
                max-width: 80px;
                margin-left: -14px;
            }
            font-size: 1.5em;
        }
        .text{
            padding-left: 40px;
            margin-bottom: 430px;
            &:before{
                width: 30px;
            }
        }
        .background{
            margin-top: 160px;
            position: absolute;
            width: 100%;
            height: 300px;
            background: $baseColor;
            .mas{
               width: 100%;
               height: 300px;
               position: relative;
               background-image: url(/images/antwerpse_fluisteraar.jpg);
               background-position: center;
               background-repeat: no-repeat;
               background-size: cover;
              opacity: 0.8;
           }
           iframe{
               height: 300px;
           }
            &:after{
                content: '';
                position: absolute;
                left: auto;
                left: 40%;
                bottom: -160px;
                height: 200px;
                width: 2px;
                background: black;
            }
        }
        .AntwerpseFlusiteraar{
            top: auto;
            bottom: -50px;
            right: 0;
            height: 350px;
            width: 80%;
            z-index: -1;
        }
    }
    .content{
        margin-top: 150px;
    }
    .contentVerhaal .verhaal:after, .contentContact .mapwrap:after{
        right: 100px !important;
    }
}

@media screen and(max-width: 320px){
    .header{
        &.beluister{
            .background{
                margin-top: 350px;
            }
        }
    }
}

@media screen and(max-width: 280px){
    .header{
        &.contact{
            .background{
                margin-top: 330px;
            }
        }
        &.feedback{
            .background{
                margin-top: 220px;
            }
        }
        &.beluister{
            .background{
                margin-top: 380px;
            }
        }
        &.verhaal{
            .background{
                margin-top: 220px;
            }
        }
    }
}

@media screen and(max-width: 250px){
    .header{
        &.beluister{
            .background{
                margin-top: 350px;
            }
        }
    }
}
