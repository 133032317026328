.loginWrap{
    max-width: 500px;
    width: 90%;
    margin: auto;
    margin-top: 80px;
    border: 1px solid rgba(0,0,0,0.05);
    text-align: center;
    .card-header{
        padding: 20px;
        background: rgba(0,0,0,0.05);
        color: black;
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 2em;
    }
    .card-body{
        padding-bottom: 30px;
    }
    fieldset{
        border: none;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        &.remember, &.button{
            max-width: 80%;
            margin: auto;
            text-align: left;
        }
        input[type='email'], input[type='password'], input[type='text']{
            width: 80%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0px;
            border: 2px solid #b7dff8;
            padding: 10px;
            font-size: 1.1em;
        }
        input{
            margin: auto;
            outline: none;
            padding: 10px 10px 8px 10px;
            &:focus{
                box-shadow: none;
            }
        }
        button{
            display: inline-block !important;
            margin-bottom: 0 !important;
            background: $baseColor;
            border-radius: 0px;
            appearance: none;
            text-align: center;
            color: black;
            margin-top: 20px;
            padding: 5px 15px !important;
            font-weight: bold;
            font-size: 1.2em !important;
            text-decoration: none;
            border: 2px solid $baseColor;
            max-width: auto !important;
            width: auto !important;
            float: right;
            &:hover{
                background: white;
                color: black;
                border: 2px solid $baseColor;
            }
        }
    }
}
.center{
    text-align: center;
}
