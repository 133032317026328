*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-weight: lighter;
	height: auto;
	outline: none;
}
html, body{
	width: 100%;
	height: 100%;
	font-size: 16px;
	margin: 0;
	padding: 0;
	font-family: 'Nexa' !important;
	background-color: transparent !important;
	line-height: 1.1 !important;
	color: black;
}
#app{
	overflow-x: hidden;
}
img{
	max-width: 100%;
  	height: auto;
  	max-height: 100%;
	border: 0;
}
a{
	text-decoration: none;
	outline: none;
	transition: all 0.5s;
  	color: inherit;
}
.container{
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	max-width: 1000px;
}
p {
  	margin-bottom: 1em;
}

.clearing{
	clear: both;
	display: block;
}

.alert-danger ul {
	list-style: none !important;
}

@media screen and(min-width: 768px){
  .container{
  	width: 100% !important;
  }
}
