iframe{
    width: calc(100% - 500px);
    height: 400px;
    position: absolute;
    right: 0;
    top: 0;
}
.contentVerhaal{
    margin-top: 250px;
    color: black;
    p{
        line-height: 150%;
        font-size: 1.05em;
    }
    .verhaal{
        margin-bottom: 200px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 300px;
            top: -150px;
            height: 150px;
            width: 2px;
            background: black;
        }
        &:after{
            content: '';
            position: absolute;
            right: 250px;
            bottom: -150px;
            height: 150px;
            width: 2px;
            background: black;
        }
        ul{
            list-style: none;
            display: block;
            margin-top: -40px;
            li{
                display: block;
                margin-bottom: 40px;
                a{
                    background: $baseColor;
                    color: white;
                    text-decoration: none;
                    padding: 10px 20px;
                    display: inline-block;
                    font-weight: 700;
                    margin-top: 10px;
                    &:hover{
                        background: darken($baseColor, 10);
                    }
                }
                .image1, .image2, .image3{
                    float: left;
                    width: 400px;
                    height: 300px;
                    position: relative;
                    .image{
                        opacity: 0.8;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: #b7dff8;
                        z-index: -1;
                    }
                }
                .text{
                    float: right;
                    min-height: 300px;
                    width: calc(100% - 450px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .image2{
                    float: right;
                }
                .text2{
                    float: left;
                    min-height: 300px;
                    width: calc(100% - 450px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .image1 .image{
                    background-image: url(/images/picture2.jpg);
                }
                .image2 .image{
                    background-image: url(/images/picture3.jpg);
                }
                .image3 .image{
                    background-image: url(/images/picture4.jpg);
                }
            }
        }
    }
    .overOns{
        margin-bottom: 100px;
        position: relative;
        .title{
            float: right;
            text-align: right;
            &:after{
                right: 30px;
                left: auto;
            }
        }
    }
    .title{
        margin-left: 80px;
        margin-right: 20px;
        position: relative;
        max-width: 250px;
        width: 100%;
        min-height: 150px;
        &:after{
            top: -45%;
            left: 30px;
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            background: $baseColor;
            z-index: -1;
        }
    }
    span{
        font-size: 3em;
        display: block;
        margin-bottom: 0;
        font-weight: bold;
        color: $baseColor;
    }
    h2{
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 0;
        letter-spacing: 2px;
        color: black;
    }
    .overOns{
        ul{
            list-style: none;
            display: block;
            li{
                float: left;
                width: calc(50% - 2px - 2em);
                padding: 30px;
                margin: 1em;
                border: 1px solid black;
                position: relative;
                h3{
                    font-size: 1.2em;
                    font-weight: 700;
                    margin-top: 0;
                }
                p{
                    margin-bottom: 0;
                }
                &:before{
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    bottom: -13px;
                    right: 20px;
                    border-style: solid;
                    border-width: 0 18px 18px 0;
                    border-color: transparent white transparent transparent;
                    z-index: 100;
                }
                &:after{
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    bottom: -16px;
                    right: 19px;
                    border-style: solid;
                    border-width: 0 20px 20px 0;
                    border-color: transparent black transparent transparent;
                }
            }
        }
    }
}

@media screen and(max-width: 1050px){
    iframe{
        width: 100%;
        height: 500px;
        margin-top: 40px;
        position: relative;
    }
    .contentVerhaal{
        margin-top: 150px;
    }
}

@media screen and(max-width: 800px){
    iframe{
        height: 400px;
    }
    .contentVerhaal{
        margin-top: 100px;
        .verhaal{
            ul{
                li{
                    margin-bottom: 0;
                    .image1, .image2, .image3{
                        float: none;
                        width: 100%;
                        height: 300px;
                    }
                    h2{
                        font-size:1.5em;
                    }
                    .text{
                        float: none;
                        min-height: auto;
                        padding: 40px 0;
                        width: 100%;
                    }
                    .image2{
                        float: none;
                    }
                    .text2{
                        float: none;
                        min-height: auto;
                        padding: 40px 0;
                        width: 100%;
                    }
                }
            }
        }
        .overOns{
            ul{
                li{
                    width: calc(100% - 2px - 2em);
                    padding: 20px;
                }
            }
        }
    }
}

@media screen and(max-width: 650px){
    iframe{
        height: 300px;
    }
}

@media screen and(max-width: 450px){
    iframe{
        height: 250px;
    }
    .verhaal{
        .text{
            margin-bottom: 0;
        }
    }
    .contentVerhaal{
        // margin-top: 500px;
        .title {
            margin-left: 0;
            h2{
                font-size: 1.5em;
            }
        }
    }
}
